import './Footer.css';

import React  from 'react';

 function Footer() {
  
  return (
    <React.Fragment>
    
 <footer>
<div class="footer">
<div class="row">
<a href="https://github.com/Krish18Joshi" target="_blank"><i class="fa fa-github"></i></a>
<a href="https://www.linkedin.com/in/krish-joshi-1ab2331b9/" target="_blank"><i class="fa fa-linkedin"></i></a>
<a href="mailto:krishjoshi791@gmail.com" target="_blank" alt="email"><i class="fa-solid fa-envelope"></i></a>
<a href="https://twitter.com/Krishjo70747820" target="_blank"><i class="fa fa-twitter"></i></a>
</div>
<br></br>
<div class="row">
<ul>
<li><a href="Contact">Contact</a></li>
<li><a href="Skills">Our Services</a></li>
<li><a href="Contact">Privacy Policy</a></li>
<li><a href="Contact">Terms & Conditions</a></li>
<li><a href="Experience">Career</a></li>
</ul>
</div>

<div class="row">
KRISH JOSHI Copyright © 2024  - All rights reserved || Designed By: Krish joshi 
</div>
</div>
</footer>

    </React.Fragment>
  )
}
export default Footer ;