import About from "../About-me/About";
import Contact from "../Contact-me/Contact"
import Experience from "../Experience/Experience"
import './Front.css';
import React  from 'react';
function Front(){
return(
    <React.Fragment>

    <div id="body">
  <p id="p1"> Hello, it's me<br /><a id="a">krish joshi</a>
  </p></div>
  <About />
  <br></br>
  <Experience />
  <br></br>
  <br></br>
<Contact />
</React.Fragment>
)

}

export default Front;
                                 