
import React , {Component,state, useState} from 'react';
import './Nav.css';
import img2 from '../images/img2.png'
import MenuItems from './Menuitems' ;
import {GiHamburgerMenu} from "react-icons/gi";
import { Link} from "react-router-dom";

import * as ReactBootStrap from 'react-bootstrap';
 
function Nav(){
const [showIcons, setshowIcons] = useState(false);
    return (<React.Fragment>
    <div>
<ReactBootStrap.Navbar bg="black" expand="lg" variant="dark" className='navigation' fixed="top"  >
  <ReactBootStrap.Container>
    <ReactBootStrap.Navbar.Brand href="/"><img className='img2' src={img2}></img></ReactBootStrap.Navbar.Brand>
    <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
    <ReactBootStrap.Navbar.Collapse id="navbarScroll">
      <ReactBootStrap.Nav className="me-auto" variant="pills" style={{ maxHeight: '150px' }}
        navbarScroll >
        <ReactBootStrap.Nav.Link href="/" className='nav-links home'><span>Home</span></ReactBootStrap.Nav.Link>
        <ReactBootStrap.Nav.Link href="About" className='nav-links'><span> About-Me</span></ReactBootStrap.Nav.Link>
        <ReactBootStrap.Nav.Link href="Skills" className='nav-links'  ><span>Skills </span></ReactBootStrap.Nav.Link>

        <ReactBootStrap.Nav.Link href="Mainpro" className='nav-links'  ><span> Projects </span></ReactBootStrap.Nav.Link>
        <ReactBootStrap.Nav.Link href="Experience" className='nav-links'  ><span> Experience</span></ReactBootStrap.Nav.Link>
        <ReactBootStrap.Nav.Link href="Certificates" className='nav-links'  ><span>Certificates </span></ReactBootStrap.Nav.Link>
        <ReactBootStrap.Nav.Link href="Interest" className='nav-links'  ><span>Interest </span></ReactBootStrap.Nav.Link>

        <ReactBootStrap.Nav.Link href="Contact" className='nav-links'  ><span> Connect</span></ReactBootStrap.Nav.Link>


      </ReactBootStrap.Nav>
    </ReactBootStrap.Navbar.Collapse>
  </ReactBootStrap.Container>
</ReactBootStrap.Navbar></div>
<br></br><br></br>
    </React.Fragment>);
}
export default Nav;