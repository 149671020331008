import React ,{ useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Skill.css';



function Skills(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
<React.Fragment>
<br></br>
<div className='s'>


<div className='outer' data-aos="zoom-out"  data-aos-easing="linear"
     data-aos-duration="1500">
<p className='project'> SKILLS </p></div><br></br>
<div className='sk'>

<div className='skills-bar' >
<div className='bar'>
        <div className='info'>
            <span>HTML <i class="fa-brands fa-html5"></i></span>
        </div>
        <div className='progress-line html'><span className='span'></span></div>
    </div>

    <div className='bar'>
        <div className='info'>
            <span>C </span>
        </div>
        <div className='progress-line c1'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>C++ </span>
        </div>
        <div className='progress-line c'><span className='span'></span></div>
    </div>
   
    <div className='bar'>
        <div className='info'>
            <span>CSS <i class="fa-brands fa-css3-alt"></i></span>
        </div>
        <div className='progress-line css'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>BOOTSTRAP <i class="fa-brands fa-bootstrap"></i></span>
        </div>
        <div className='progress-line bootstrap'><span className='span'></span></div>
    </div>
    
    <div className='bar'>
        <div className='info'>
            <span>JAVASCRIPT <i class="fa-brands fa-js-square"></i></span>
        </div>
        
        <div className='progress-line javascript'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>Python <i class="fa-brands fa-python"></i></span>
        </div>
        
        <div className='progress-line javascript'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>REACT.JS <i class="fa-brands fa-react"></i></span>
        </div>
        <div className='progress-line react'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>GIT / GITHUB <i class="fa-brands fa-github"></i></span>
        </div>
        <div className='progress-line node'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>NODE.JS <i class="fa-brands fa-node"></i></span>
        </div>
        <div className='progress-line node'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>EXPRESS.JS ex</span>
        </div>
        <div className='progress-line express'><span className='span'></span></div>
    </div>
    
    <div className='bar'>
        <div className='info'>
            <span>MONGODB <i class="fa-solid fa-database"></i></span>
        </div>
        <div className='progress-line mongodb'><span className='span'></span></div>
    </div>
     
    <div className='bar'>
        <div className='info'>
            <span>MYSQL <i class="fa-solid fa-database"></i></span>
        </div>
        <div className='progress-line mongodb'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>FIREBASE <i class="fa-solid fa-database"></i></span>
        </div>
        <div className='progress-line mongodb'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>Tableau</span>
        </div>
        <div className='progress-line mongodb'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>Streamlit</span>
        </div>
        <div className='progress-line mongodb'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>Web Scraping "SELENIUM" </span>
        </div>
        <div className='progress-line jquery'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>JQUERY </span>
        </div>
        <div className='progress-line jquery'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>Data Science "Machine Learning" <i class="fa-solid fa-chalkboard-user"></i></span>
        </div>
        <div className='progress-line jquery'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>DATA STRUCTURE </span>
        </div>
        <div className='progress-line datas'><span className='span'></span></div>
    </div>
   
    <div className='bar'>
        <div className='info'>
            <span>ALGORITHM</span>
        </div>
        <div className='progress-line algo'><span className='span'></span></div>
    </div> 
            <h2 style={{color:'#22A39F'}}  ><b>IN PROGRESS</b></h2>
      
    <div className='bar'>
        <div className='info'>
            <span>ANDROID STUDIO</span>
        </div>
        <div className='progress-line prog'><span className='span'></span></div>
    </div>
    <div className='bar'>
        <div className='info'>
            <span>FLUTTER</span>
        </div>
        <div className='progress-line prog'><span className='span'></span></div>
    </div>

    <div className='bar'>
        <div className='info'>
            <span>DART</span>
        </div>
        <div className='progress-line prog'><span className='span'></span></div>
    </div>
    


</div></div></div>
</React.Fragment>

    );
}

export default Skills;