import './Contact.css';
import AOS from 'aos';
import  emailjs from 'emailjs-com';
import 'aos/dist/aos.css';
import React , { useEffect } from 'react';


import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  fetch("https://type.fit/api/quotes").then(data => data.json()).then(quotes => {

    const x =Math.floor((Math.random() * 100) + 1);
const quote = quotes[x].text;
console.log(quote);
const view = document.getElementById("hh");
view.innerHTML= quote;
  });
export default function Contact() {
  
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

const sendEmail = (e)=>{
  e.preventDefault();
 
emailjs.sendForm('service_sy7dobo', 'template_hinrx3j', e.target, 'cakQTvnBidJJk2TCY')
.then((result) => {

  toast.success(' Your message is send 👍 ', {
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
   
    });
    console.log(result.text);
}, (error) => {
  toast.error('🦄 Some error!', {
    position: "top-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    // console.log(error.text);
});



}

  return (
    <React.Fragment>
   <div className='ott' > 
   <p  data-aos="zoom-in"  data-aos-easing="linear"
     data-aos-duration="1500" className='ot'> Contact-Me</p>
   “ <span id='hh'>If I had asked people what they wanted, they would have said faster horses.</span> ”<br></br><hr className='underline5'></hr><br></br>
     </div>
     
     <div className='contact' >
     <div id='krishcontact'>
     <form onSubmit={sendEmail}  data-aos="zoom-in"  data-aos-easing="linear"
     data-aos-duration="1500">
     <div className='row'>
     <div class="inputgroup">
     <input type='text' id="name" name="name" required/>
     <label for="name" className='lable'> <i class="fa-solid fa-user"></i> Your Name </label>
      
      </div>
      
      <div class="inputgroup">
      <input type='number' id="Phone_number" name="Phone_number" required/>
      <label for="name"  className='lable'>  <i class="fa-solid fa-phone"></i> Phone Number </label>
      
      </div>
      </div>
      <div class="inputgroup">
      <input type='email' id="user_email" name="user_email" required/>
      <label for="name"  className='lable'><i class="fa-solid fa-envelope"></i>  Email ID </label>
      
      </div>
      <div class="inputgroup"> <textarea type='text' rows= '10' id="message" name="message" required/><label for="name"  className='lable'><i class="fa-solid fa-comments"></i>  Message  </label>
     
      </div>
      <div class="inputgroup">
      <input type='submit' id="submit" className='buttonc' name="submit" value= 'Submit'/>
      </div>
     </form>
     </div>
     
     </div>

     <ToastContainer
position="top-center"
autoClose={6000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss={false}
draggable
pauseOnHover

/>
    </React.Fragment>
  )
}
