import React ,{ useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Intrest.css';
// import i1 from "../Interest/intimg/int.png";
// import i2 from "../Interest/intimg/init2.webp";
// import i3 from "../Interest/intimg/int3.jpeg";
// import i4 from "../Interest/intimg/int4.jpeg";
// import i5 from "../Interest/intimg/int5.jpeg";
// import i6 from "../Interest/intimg/int6.jpeg";

function Interest(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return(
<React.Fragment>
<div className='mainint'>
<div className='headd'  data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "4000">
<div className='lefti' >Web Development <div className='leftiin'>
<div className='llll'>
Web development refers to the building , creating , and maintaining of websites. It includes aspects such as web design, web publishing, web programming,and database management.It is the creation of an application that works over the internet i.e. websites.</div>
</div></div>
</div>
<div className='headd'  data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Cloud Computing
<div className='leftiin'>
<div className='llll'>

Image result for cloud computing Cloud computing is a technology that uses the internet for storing and managing data on remote servers and then access data via the internet. ... One such example is Google cloud –
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Blockchain Technology
<div className='leftiin'>
<div className='llll'>

Blockchain technology is an advanced database mechanism that allows transparent information sharing within a business network. A blockchain database stores data in blocks that are linked together in a chain.The data is chronologically consistent because you cannot delete or modify the chain without consensus from the network
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > App Development
<div className='leftiin'>
<div className='llll'>

Mobile application development is the process of creating apps that run on any mobile platform: Android and iOS. With everything you need right at your fingertips, you can track inventory or make instant updates from your phone.
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Ethical Hacking
<div className='leftiin'>
<div className='llll'>

Ethical hacking is a process of detecting vulnerabilities in an application , system , or organization's infrastructure that an attacker can use to exploit an organization.They use this process to prevent cyberattacks and security breaches by lawful
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Data Science
<div className='leftiin'>
<div className='llll'>

Data science defined Data science encompasses preparing data for analysis, including cleansing , aggregating , and manipulating the data to perform advanced data analysis.Data science is related to data mining, machine learning and big data.
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Software Enginnering
<div className='leftiin'>
<div className='llll'>

Software engineering is defined as a process of analyzing user requirements and then designing, building, and testing software application which will satisfy those requirements. software which is reliable and works efficiently on the real machines'.
</div>
</div>


</div>
</div>
<div className='headd' data-aos="fade-up"  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000">
<div className='lefti'  > Blockchain Technology
<div className='leftiin'>
<div className='llll'>

Blockchain technology is an advanced database mechanism that allows transparent information sharing within a business network. A blockchain database stores data in blocks that are linked together in a chain.The data is chronologically consistent because you cannot delete or modify the chain without consensus from the network
</div>
</div>


</div>
</div>
</div>
</React.Fragment>

    );
}

export default Interest;