import './Projects.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React , { useEffect } from 'react';

 function Projects (props){
   
    useEffect(() => {
     
        AOS.init();
        AOS.refresh();
      }, []);
     
    return (
    <React.Fragment >
 
<div className='PS ' data-aos="zoom-in"  data-aos-easing="linear"
     data-aos-duration="1500">
<div className='PS2' data-aos="zoom-out"  
     data-aos-duration="1500" >
<h2 className='hh'> {props.project}</h2>
<img className='img' src= {props.img} alt="Projectimage" />
<p className='pq'>{props.p}
</p>

<a href={props.link} target="_blank" rel="noreferrer" className='aw'> " Project Link " </a></div>
</div>
    </React.Fragment>);
 }
 export default Projects ;