import p1 from '../images/p1.png'
import p2 from '../images/p2.png'
import p3 from '../images/p3.png'
import p4 from '../images/p4.png'
import p5 from '../images/p5.png'
import p6 from '../images/p6.png'
import p7 from '../images/p7.png'
import p8 from '../images/p8.png'
import p9 from '../images/p9.png'
import p10 from '../images/p10.png'
import p11 from '../images/p11.png'
import p12 from '../images/p12.png'
import p13 from '../images/p13.png'
const Proj =[
    {
        project : "where's my money?",
        img: p12,
        p:"A website or mobile app by which user can easily manage money and also help in calculating emi  ,It takes seconds to record daily transactions. Put them into clear and visualized categories such as Expense: Food, Shopping or Income: Salary, Gift. "
        ,
        Link : "https://where-s-my-money-18.web.app/"
      
    },
    {
        project : "Movlit",
        img: p11,
        p:"Movlit is an online video streaming platform , currently offers over 100,00 hours of TV content and movies across different languages. Highly evolved video streaming technology and a high attention to quality of experience across devices and platforms."
        ,
        Link : "https://movlit-c793b.web.app/"
      
    },
    {
        project : "FoodiExpress",
        img: p13,
        p:"Welcome to FoodieExpress, the ultimate destination for food lovers seeking convenience and an exceptional culinary experience. We are passionate about connecting hungry customers with a diverse range of local restaurants, all at the click of a button.        "
        ,
        Link : "https://foodieexpress-lrm0.onrender.com"
      
    },
    {
        project : "SunShine",
        img: p1,
        p:"The weather can change in a split second. Your safety depends on timely nowcasts and forecasts that provide understandable information you can use. While we once relied on radio and television for updated information, today, the Sun Shine replace it."
        ,
        Link : "https://krishweather.netlify.app/"
      
    },
    {
        project : "Cybernated",
        img: p10,
        p:"Allows you to track the total amount and value of your cryptocurrencies across all  exchanges, platforms, and blockchains in real-time. Give the actual live price of any cryptocurrencies so that you can securely and accurately calculate your capital gains & losses."
        ,
        Link : "https://finance-cryptotracker.netlify.app/"
      
    },
    {
        project : "Sudoku-Solver",
        img: p4,
        p:"A puzzle in which players insert the numbers one to nine into a grid consisting of nine squares subdivided into a further nine smaller squares in such a way that every number appears once in each horizontal line and you can also set time and check sudoku ."
        ,
        Link : "https://sudoku-example-krish.netlify.app/"
    },
    {
        project :"Password Generator",
        img: p2,
        p:"Password generator is a software tool that creates random or customized passwords for users. It helps users create stronger passwords that provide greater security for a given type of access and this project is made by  using React.js ",
        Link : "https://krish18joshi.github.io/Password-Generator/"
    },{
        project :"Tic-Tac-Toe",
        img: p3,
        p:"The most ancient board games still played today is tic tac toe. This is probably because it's so simple that you don't even need a board to play! the history of tic tac toe is more rich and surprising than you'd think and this project is made by  using React.js "
        ,
        Link : "https://krish18joshi.github.io/Tic-Tac-Toe/"
    },{
        project  :"Search-bar",
        img: p5,
        p:"A website search bar is a bar that (typically) lives in the top navigation of a website and allows users to search for content rather than browse your website using the given navigation options. The user clicks the search bar and the magnifying glass icon."
        ,
        Link : "https://searchbar-krishjoshi.netlify.app/"
    },{
        project : "Drumkit",
        img: p6,
        p:"Virtual Drumming is a collection of free online virtual games where you can play songs,with the virtual drums of the best drummers. You can play big heavy metal drum kits with a lot of cymbals and double bass drum, essential jazz drums or amazing rock drum sets."
        ,
        Link : "https://krish18joshi.github.io/Drumkit-krish/"
    },
    {
        project : "Stockzo",
        img: p7,
        p:"Stockzo is a discount broking, portfolio management, and financial services startup firm trading like any other broking platform. We are building the same with open wallet, games related to stock market ,finance management, calculator, etc."
        ,
        Link : "https://krish18joshi.github.io/STOCKZO/"
    },
    {
        project : "Calculator-Extension",
        img: p8,
        p:"A simple calculator for basic arithmetic.Adds a calculator icon to your browser toolbar .You can open the calculator while browsing any web page .Compact and available when you need it right from the Chrome toolbar.Use the keyboard number pad for calculations"
        ,
        Link : "https://github.com/Krish18Joshi/Calculator-Extension"
    },
    {
        project : "Quotes-Extension",
        img: p9,
        p:"Motivation builds determination to achieve something, like losing weight or securing a new job, and when you succeed. So, if you continue to motivate yourself and set and achieve new goals, you’ll generally feel happier than you did before ."
        ,
        Link : "https://github.com/Krish18Joshi/Short-Quotes-Extension"
    }
]

    

export default Proj;