import React from 'react';
import './Exp.css';
import img3 from '../images/name-2.png'

function Experience(){
    
    return(
<React.Fragment>
<div className='Ex'>
<br></br>
 <div className='Ex1'> 
 <div className='Ex2'>
 <h1 style={{color:"white"}} className='hhhh'> 


Experience</h1><img className='kimg' src={img3} alt="imglaptop"></img>
<div className='infoex'>
<div className='infouter'>
<h4> Dabotics </h4><p className='para'> July 2023 - Aug 2023

</p>
</div>
<div className='experinfo'> <h5>Internship - WEB DEVELOPER</h5> <p>
<li>Build reusable code and libraries for future use</li>
<li>Optimize applications for maximum speed and
scalability</li>
<li>Identify and fix bugs that are found within code
bases.</li>
 </p><p> 





<a  className='creat' href='https://www.dabotics.org/' target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-location-dot"></i> Dabotics</a> |  <a  className='creat' href='https://drive.google.com/file/d/1BeG3TDab9nNsK6Wm9mzqc1chlhWtvm9t/view?usp=drive_link' target="_blank" rel="noopener noreferrer">Certificate</a></p>
</div>
<div className='infouter'>
<h4> Creatiwise | Technology solutions and services.</h4><p className='para'>July 2022 - Jan 2023

</p>
</div>
<div className='experinfo'> <h5>Internship - Javascript developer</h5> <p><li>Develop multiple websites and applications from start to finish</li><li>Increased user experience by 20% and
Implemented coding best practices that reduced rework by 25% and cost by 12%.</li>
<li>Work with the other members of the Web Team to develop exciting features.
and collaborate with design and marketing teams to ensure seamless user experiences</li>
 </p><p> 





<a  className='creat' href='https://creatiwise.com/' target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-location-dot"></i> Creatiwise</a> |  <a  className='creat' href='https://internshala.com/student/certificate/111447491/EC02F5E0-384D-9127-8937-9AD38470FBEA' target="_blank" rel="noopener noreferrer">Certificate</a></p>
</div>

</div>



</div>

</div>

</div>

</React.Fragment>

    );
}

export default Experience;