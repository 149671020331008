import React ,{ useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Projects from './Projects';
import Proj from './Project';
import './Projects.css';


function Mainpro(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      const items = Proj;
      const listItems = items.map((item) =>  
      <div>
       <Projects project = {item.project} img ={item.img} link = {item.Link} p = {item.p} />
         </div>
      );  
      
    return(
<React.Fragment>

<br></br><br></br>
<div className='outer' data-aos="zoom-out"  data-aos-easing="linear"
     data-aos-duration="1500">
<p className='project'> PROJECTS </p></div>
<br></br>

   {listItems}

</React.Fragment>

    );
}

export default Mainpro;