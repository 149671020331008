import React ,{ useEffect } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import './App.css';
import img1 from '../images/img1.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';

function About(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      
    return(
<React.Fragment   >
<br></br>
<div id="about-me" >
<div className='pic' data-aos="fade-down"  data-aos-easing="linear"
     data-aos-duration="1500"  
     >
<div className='ab'><img className='img1' src={img1}></img></div>
</div>

  <div id="kr"class="font-effect-emboss"><h1 id="h1"><span id="cout">
  <span id="aboute" style ={ { color: '#22A39F'}}> ABOUT-ME</span> </span>
  <br></br>
  <p style ={ { color: 'white'}}> <span className='sp'> <span className='spne'> I'</span> a<span className='spne'>m</span> a    </span>
  <ReactTypingEffect  style ={ { color: 'white'}}
        text={[" Web Developer","Data Scientist", " Coder"," Software Enginner"," Freelancer"]}
        cursorRenderer={cursor => <h1>{cursor}</h1>}
        displayTextRenderer={(text, i) => {
          return (
            <h1>
              {text.split('').map((char, i) => {
                const key = `${i}`;
                return (
                  <span
                    key={key}
                    style={i%2 === 0 ? {} : { color: '#22A39F'}}
                  >{char}</span>
                );
              })}
            </h1>
          );
        }}        
      /></p>
</h1>
<br></br></div></div>
<div className='b'>
<div className='ab2'><b><span className='uu' >Introduction</span></b> <p className='pp'> Hi, I'm <span style={{color:"#00fff7"}}>Krish Joshi</span> a passionate and energetic professional Competitive coder , Programmer and Software engineer and I am an fullstack developer , a programmer with good knowledge of front-end techniques. I love structure and order and I also stand for quality. I love spending time on fixing little details and optimizing web apps. Also I like working in a team, you'll learn faster and much more. As the saying goes: 'two heads are better than one' and Provide high quality professional websites  and I'm pursuing my degree from Chandigarh University branch Bech "CSE" (8.64cgpa "Till Now"). and also working on "Data structure and algorithm  and  nowadays I am Actively seeking for an opportunity to work in a challenging
environment to prove my skills and utilize my knowledge and
intelligence in the growth of the organization and I wish to
work with a diverse team that works towards contributing to
the company`s vision and goals and looking for a dynamic
and progressive company where my skills are utilised in the
maximum way possible.<span style={{color:"#00fff7"}}> # Upgrading Skills.</span> </p>

 <span className='uu' > <b>Where I am from</b>
</span><p className='pp'>I live in Rajasthan (Jaipur). <br></br> Jaipur, the capital of Rajasthan is popularly known as the Pink City. With broad avenues and spacious gardens, the city is steeped in history and culture. Here the past comes alive in magnificent forts and palaces, blushed pink, where once lived the maharajas. The bustling bazaars of Jaipur, famous for Rajasthan jewellery, fabric and shoes, possess a timeless quality and are a treasure-trove for the shoppers. 

Planned by Vidyadhar Bhattacharya, Jaipur holds the distinction of being the first planned city of India. Renowned globally for its coloured gems, the capital city of Rajasthan combines the allure of its ancient history with all the advantages of a metropolis. Jaipur rises up majestically against the backdrop of the forts Nahargarh, Jaigarh and Garh Ganesh Temple. The bustling modern city is one of the three corners of the golden triangle that includes Delhi, Agra and Jaipur.</p> 
<span className='uu' > <b>My Hobbies</b>
</span><p className='pp'>“ I usually spend my leisure time coding or learning new technology. I like reading new and trending novels and my favourite genres include suspense, personal development  and thriller novels.  and I like to ride bikes and I am into software development and also  Having a gym in the workplace is a great idea as employees can get to know each other better on a casual level apart from their designated roles. ” </p>   </div></div><br></br>

</React.Fragment>

    );
}

export default About;