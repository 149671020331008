import React ,{ useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Ce.css';



function Certificate(props){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);


    return(
<React.Fragment>

<div className='cer' style={{ backgroundImage:`url(${props.img})` }}  data-aos={props.ani}  data-aos-easing="linear"
     data-aos-duration="1500" delay = "2000"> <div className='cc' >{props.info}</div> </div>

</React.Fragment>

    );
}

export default Certificate;