
import './App.css';
import React from 'react';
import Front from './components/Front/Front';
import Nav from './components/Nav/Nav';
import { BrowserRouter as Router ,Route ,Switch } from "react-router-dom";
import Mainpro from './components/Projects/Mainpro';
import Contact from './components/Contact-me/Contact';
import Experience from './components/Experience/Experience';
import About from './components/About-me/About'
import Skills from './components/Skills/Skills';
import Interest from './components/Interest/Interest';
import Certification from './components/Certificates/Certificates';
import Footer from './components/Footer/Footer';


function App() {
  
  return (
    <Router>
    
<Nav />


<Switch >  
<Route  exact path='/' component={Front}/>
<Route  exact path='/About' component={About}/>
<Route  exact path='/Certificates' component={Certification}/>
<Route  exact path='/Skills' component={Skills}/>
<Route  exact path='/Interest' component={Interest}/>
  <Route  exact path='/Experience' component={Experience}/>
  <Route  exact path='/Mainpro' component={Mainpro}/>
  <Route  exact path='/Contact' component={Contact}/>

</Switch>
 

<br></br>
<Footer />
   
  
    </Router>);
}

export default App;
