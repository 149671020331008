import c1 from "../Certificates/cerimg/c1.png";
import c2 from "../Certificates/cerimg/c2.png";
import c3 from "../Certificates/cerimg/c3.png";
import c4 from "../Certificates/cerimg/c4.png";
import c5 from "../Certificates/cerimg/c5.png";
import c6 from "../Certificates/cerimg/c6.png";
import c7 from "../Certificates/cerimg/c7.png";
import c8 from "../Certificates/cerimg/c8.png";
import c9 from "../Certificates/cerimg/c9.png";
import c10 from "../Certificates/cerimg/c10.png";
import c11 from "../Certificates/cerimg/c11.png";
import c12 from "../Certificates/cerimg/c12.png";
import c13 from "../Certificates/cerimg/c13.png";
import c14 from "../Certificates/cerimg/c14.png";
import c15 from "../Certificates/cerimg/c15.png";
import c16 from "../Certificates/cerimg/c16.png";
import c17 from "../Certificates/cerimg/c17.png";
import c18 from "../Certificates/cerimg/c18.png";
const  Data = [
    {   
        img : c15,
        ani:"zoom-in"
    },
    {   
        img : c16,
        ani:"zoom-in"
    },
    {   
        img : c18,
        ani:"zoom-in"
    },
    {   
        img : c17,
        ani:"zoom-in"
    },
    {   
        img : c14,
        ani:"zoom-in"
    },
    {   
    img : c13,
    ani:"zoom-in"
},
{   
    img : c1,
    ani:"zoom-in"
},
{   
    img : c2,
    ani:"zoom-in"
},
{   
img : c3,
ani:"zoom-in"
},
{   
img : c4,
ani:"zoom-in"
},
{   
img : c5,
ani:"zoom-in"
},

{   
img : c7,
ani:"zoom-in"
},
{   
    img : c8,
    ani:"zoom-in"
},
{   
    img : c9,
    ani:"zoom-in"
},
{   
    img : c12,
    ani:"zoom-in"
},
{   
    img : c10,
    ani:"zoom-in"
},
{   
    img : c11,
    ani:"zoom-in"
}


]

export default Data;

//{   info: "info hu mai ",
// img : c13,
// ani:"zoom-in"
// }