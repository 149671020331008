import React ,{ useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Ce.css';
import Data from './Cerdata';
import Certificate from './Certificates2';

function Certification(){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
      
const Cer = Data.map((items)=>
     <Certificate img = {items.img}  info = {items.info} ani ={items.ani}/>

   
)

    return(
<React.Fragment>
<br></br>
<div>{Cer}</div>

</React.Fragment>

    );
}

export default Certification;